<template>
  <div class="exam">
    <div v-if="questionList">
      <div
        :id="'a'+section+index"
        v-for="(item, index) in questionList"
        :key="item._id.$id"
        style="margin-bottom:50px"
      >
        <div class="title">
          <div class="questionTitle">{{ item.extra_index?item.extra_index:index + 1 }}.</div>
          <div v-html="item.question_question"></div>
        </div>
        <div class="option" v-if="item.question_optionA">
          <el-radio
            @change="choose('A', item._id.$id)"
            v-model="questionList[index]['select']"
            label="A"
          >
            <span class="optionTitle">A</span>
            </el-radio><div class="div_p" v-html="item.question_optionA"></div>
        </div>
        <div class="option" v-if="item.question_optionB">
          <el-radio
            @change="choose('B', item._id.$id)"
            v-model="questionList[index]['select']"
            label="B"
          >
            <span class="optionTitle">B</span>
            </el-radio>
            <div class="div_p" v-html="item.question_optionB"></div>
        </div>
        <div class="option" v-if="item.question_optionC">
            <el-radio
                @change="choose('C', item._id.$id)"
                v-model="questionList[index]['select']"
                label="C"
            >
                <span class="optionTitle">C</span>
            </el-radio>
            <div class="div_p" v-html="item.question_optionC"></div>
        </div>
        <div class="option" v-if="item.question_optionD">
            <el-radio
                @choose="choose('D', item._id.$id)"
                v-model="questionList[index]['select']"
                label="D"
            >
                <span class="optionTitle">D</span>
            </el-radio>
            <div class="div_p" v-html="item.question_optionD"></div>
        </div>
        <div class="option" v-if="item.question_optionE">
          <el-radio
            @change="choose('E', item._id.$id)"
            v-model="questionList[index]['select']"
            label="E"
          >
            <span class="optionTitle">E</span>
          </el-radio>
          <div class="div_p" v-html="item.question_optionE"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "exam",
  props: ["questions","section"],
  data() {
    return {
      questionList: this.questions
    };
  },
  methods: {
    choose(choice, id) {
      this.$emit("submitAnswer", { choice: choice, id: id });
    }
  }
};
</script>

<style>
.exam {
  margin: 0 auto;
}
.option {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 14px;
    margin-top: 16px;
    border-radius: 10px;
    font-weight: bold;
    color: #072D76;
}
.optionTitle {
  font-size: 18px;
  margin-right: 10px;
  color: #072D76;
}
.questionTitle{
    margin-right: 10px;
}
.btn-group {
  margin-top: 20px;
}

.title {
  display: flex;
  color: #072D76;
  font-size: 16px;
  font-weight: bold;
  line-height: 32px;
  margin-top:20px
}

/* .exam img {
  width: 100px;
} */
.el-button {
  /* width: 80px; */
  height: 40px;
}
.el-button > p {
  margin-top: 1px;
}
.el-radio__label {
  /* display: none; */
}
.el-radio {
  color: #333333;
  cursor: pointer;
  margin-right: 10px;
}
.el-radio__inner {
  border: 1px solid #ccc;
}
.div_p {
  font-size: 16px;
  display: inline-block;
  color: #072D76;
}
</style>
